<!-- Settings -->
<template>
    <div class="card card-custom" v-loading="loading">
     
        <div class="card-body p-0">
            <!--begin: Settings-->
            <div class="wizard wizard-1" id="kt_wizard_v1" data-wizard-state="step-first" data-wizard-clickable="true">
                <!--begin: Settings Body-->
                <div class="row justify-content-center my-10 px-8 px-lg-10">
                    <div class="col-xl-12 col-xxl-10 invoicepage">
                        <!--begin: Wizard Form-->
                        <form class="form" id="kt_form printSection">
                            <!--begin: Wizard Step 1-->
                            <div class="pb-5" data-wizard-type="step-content" data-wizard-state="current"> 
                              <el-row>
                                  <el-col :span="24" align="right">
                                    <el-dropdown
                                      @command="handleActions($event)"
                                      size="medium"
                                      split-button
                                      class="action_btn"
                                    >
                                      Actions
                                      <el-dropdown-menu slot="dropdown" class="action_dropdown">
                                        <el-dropdown-item :command="1">Print</el-dropdown-item>
                                          </el-dropdown-menu>
                                        </el-dropdown>
                                      </el-col>
                                    </el-row>                             
                                  <el-row>
                                        <el-col :span="12" align="left" v-if="view.website_details">
                                          <span v-loadimage="view.website_details.website_logo" ></span>                                           
                                        </el-col>
                                      
                                        <el-col :span="12" align="right">
                                        <b >
                                        <label class="mt-10">{{view.website_details.address}}, {{view.website_details.city_details.city_name}},{{view.website_details.state_details.state_name}}</label><br>                                      
                                        <label>{{view.website_details.post_code}}, {{view.website_details.country}}</label><br>
                                        <label>Phone: {{view.website_details.phone}}</label><br>
                                        <label>Fax: {{view.website_details.fax}}</label><br>
                                        <label>Email: {{view.website_details.email_address}}</label>
                                        </b>
                                        <br>
                                        </el-col>
                                  </el-row>
                                  <hr class="invoice-line">
                                  <el-row>                                    
                                      <el-col :span="24" align="center">
                                      <h2 style="font-family: Arial;text-align: center;"><b>ORDER VERIFIED DETAILS</b></h2>
                                      </el-col>                                                                            
                                  </el-row>
                                  <el-row style="margin-top:5px; margin-bottom:5px;">
                                       <el-col :span="12"  align="left">
                                           <span><b>ORDER : </b></span><span><b> {{view.order_number}}</b></span>
                                       </el-col>
                                        <el-col :span="12"  align="right">
                                           <span><b>CUSTOMER ID : </b></span><span><b>{{view.customer.customer_id}}</b></span>
                                       </el-col>
                                  </el-row>
                                    <el-row class="add-info">
                                      <el-col :span="8" >
                                          <div class="billing-adr" style="padding-left:10px;">BILL TO : </div>
                                          <div style="padding: 3px" v-if="view.customer.company_name">{{view.customer.company_name}}</div>
                                          <div style="padding:3px" v-if="view.customer"><span v-if="view.customer.first_name">{{view.customer.first_name}}</span><span v-if="view.customer.last_name">{{view.customer.last_name}}</span></div>
                                          <div style="padding: 3px;" ><span v-if="view.billing_address_order.address_line_2">{{view.billing_address_order.address_line_2}}</span><span v-if="view.billing_address_order.address_line_1">, Unit No : {{view.billing_address_order.address_line_1 }}</span></div>
                                          <div style="padding: 3px;" v-if="view.billing_address_order.city_details">{{view.billing_address_order.city_details.city_name}}</div>
                                          <div style="padding: 3px;" v-if="view.billing_address_order.state_details">{{view.billing_address_order.state_details.state_name+' - '+view.billing_address_order.post_code}}</div>
                                      </el-col>
                                      <el-col :span="8" align="left" :offset="8" v-if="view.shipping_address_order">
                                         <div class="billing-adr" style="padding-left:10px;">SHIP TO :</div>
                                         <div style="padding: 3px" v-if="view.customer.company_name">{{view.customer.company_name}}</div>
                                         <div style="padding:3px" v-if="view.customer">{{view.customer?view.customer.first_name+' '+(view.customer.last_name==null?'':view.customer.last_name):''}}</div>
                                          <div style="padding: 3px;" ><span v-if="view.shipping_address_order.address_line_2">{{view.shipping_address_order.address_line_2}}</span><span v-if="view.shipping_address_order.address_line_1">, Unit No : {{view.shipping_address_order.address_line_1 }}</span></div>
                                          <div style="padding: 3px;" v-if="view.shipping_address_order.city_details">{{view.shipping_address_order.city_details.city_name}}</div>
                                          <div style="padding: 3px;" v-if="view.shipping_address_order.state_details">{{view.shipping_address_order.state_details.state_name+' - '+view.shipping_address_order.post_code}}</div>
                                      </el-col>
                                    </el-row>
                                     
                                 <el-table :data="form.products"   @selection-change="handleSelectionChange" >                                                         
                                  <el-table-column
                                   type="selection"                                   
                                    width="50">                                    
                                  </el-table-column>
                                  <el-table-column label="Item #" width="100" >
                                       <template slot-scope="scope">
                                         <span>{{form.products[scope.$index].product_item.sku}}</span>                                                                                  
                                      </template>
                                  </el-table-column>
                                  <el-table-column label="Product Name"  >                                     
                                       <template slot-scope="scope">
                                         <span>{{form.products[scope.$index].product_item.name}}</span>                                         
                                      </template>
                                  </el-table-column>                                  
                                  <el-table-column label="Quantity" width="100" align="center">
                                      <template slot-scope="scope">
                                        <span>{{form.products[scope.$index].back_quantity}}</span>                                         
                                      </template>
                                  </el-table-column>                                  
                                  <el-table-column label="Delivery Quantity" width="150" align="center">
                                     <template slot-scope="scope">
                                       <el-input-number class="return_product" v-model="form.products[scope.$index].verify_quantity" auto-complete="nope"  :controls="false"  
                                       :max="form.products[scope.$index].back_quantity" :disabled="!scope.row.edited"></el-input-number>                                          
                                      </template>                            
                                  </el-table-column>                          
                                </el-table> 
                                <span v-if="quantity_error" class="red mt-3">{{quantity_error}}</span>  
                                <h4 class="print-verified">Order Verified By : </h4>
                                  <el-row style="margin-top:25px;" class="printPageButton">
                                      <el-form >
                                      <el-col :span="6" >                                             
                                        <el-form-item label="Order Verified By" prop="verified_by" >
                                            <el-input v-model="form.order_verified_by" @input.native="capitalize" auto-complete="nope"  class="sub-total"
                                            :class=" form.errors.has('order_verified_by') ? 'error required' : 'required' "></el-input>
                                        </el-form-item>  
                                        <span class="form-err" v-if="form.errors.has('order_verified_by')" >{{form.errors.get('order_verified_by')}}</span> 
                                      </el-col>                                     
                                   
                                      <el-col :span="5" :offset="2" style="margin-top:20px">
                                      <el-form-item >
                                            <el-button type="primary" @click="createDeliveryOrder" >{{submit_btn_text}}</el-button>
                                      </el-form-item>
                                      </el-col>
                                      </el-form>
                                         </el-row>                               
                                                                     
                                       <!-- <el-row>                                      
                                      <el-col :span="24" align="left">
                                      <div style="text-align: center;"><b>If you have any questions about this order, please contact</b></div>
                                      <div style="text-align: center; font-size:15px;"><b>EMAIL: INFO@CTSBUILDINGSUPPLIES.COM</b></div>
                                      </el-col>
                                       </el-row>                                      -->
                              
                               </div>
                            
                        </form>
                        <!--end: Settings Form-->
                    </div>
                </div>
                <!--end: Settings Body-->
            </div>
        </div>
        <!--end: Settings-->
    </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import moment from 'moment';
import {downloadPdf} from "@/api/order";
import {getCorder} from "@/api/orderStatus"
import SendEmail from '@/components/SendEmail';
import Form from '@/core/services/api.form.services'
export default {
  name: 'view-order',
  components: {},
   data() {
    return {
      loading: false,
      view:[],
      viewitem:[],
      view_type: 'invoice',
       api_url:'order/delivery/create',
       form : new Form({
        order_verified_by:null,
        order_id:null,
        order_status:null,       
          products: [
           {
             id :null,
             product_id: null,                       
             quantity: null,            
             verify_quantity:null,
             back_quantity:null,
             product_item: [{
               sku: null,
               name:null
             }] ,
             edited: false,           
           }
         ]

       }),
       submit_btn_text:'Verified',
        orderStatus: [
            { id: "pickup", text: "Ready to Pick Up" },
            { id: "processing", text: "Processing" },
            { id: "on-hold", text: "On Hold" },
            { id: "completed", text: "Completed" },
            { id: "cancelled", text: "Cancelled" },
            { id: "refunded", text: "Refunded" },
            { id: "failed", text: "Failed" },
      ],
      quantity_error:null,
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Order Status", route: "/order/status" },
      { title: "Add Order Status" }
    ]);
  },
  created() {
    this.fetchData(1)   
  },
  methods: {
    fetchData() {
      this.loading = true
      getCorder(this.$route.params.order_id).then(response => {
        this.form.order_id = this.$route.params.order_id;
        this.view = response.data.data 
        this.form.products = response.data.data.order_items;       
        this.loading = false
      });
    },
    createDeliveryOrder: function() { 
      this.loading = true;
      this.quantity_error = '';  
       let sum = 0;             
     this.form.products.map((value) => {   
        if(value.verify_quantity == null || value.verify_quantity == 'undefined'){  
          this.quantity_error = '* Please select verify quantity';
        }else{
          sum += value.verify_quantity ;        
        }
     })
     if(sum <= 0){
       this.quantity_error = '* Please select verify quantity';
     }

     if(!this.quantity_error){
       this.form.post(this.api_url).then((response) => {         
            if(response.status){
              if(response.data.order_status == 'processing'){
                  this.$router.push({ path: '/order/processing' });
              }else{
                  this.$router.push({ path: '/order/list' });                  
              }
                
                this.loading=false;
                this.$showResponse('success', response.message);
            }
          }).catch((error) => {
              this.loading = false;
                console.log(error)
              })
     }
     
    }, 
    printWindow: function () {		
	     window.print();
    },
    format_date(value){
        if (value) {
        return moment(String(value)).format('DD-MM-YYYY')
        }
    },
    handleSelectionChange(val) { 
        this.multipleSelection = val;  
       this.form.products.map((check)=>{
          check.verify_quantity = 0;
          check.edited = false       
          val.map((selected)=>{
            if(check.id == selected.id){
                check.verify_quantity =  check.back_quantity 
                check.edited = true                
              }
            })
         
       })       
      
      },
      
    handleActions(evt){
      if (evt === 1){
        this.printWindow();
      }else if (evt === 2){
        this.loading=true;
        downloadPdf(this.$route.params.order_id, this.view_type).then(response => {
          //console.log(response.data);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'invoice.pdf');
          document.body.appendChild(link);
          link.click();
          this.loading = false;
        }).catch(err => {
          console.log(err);
          this.loading = false;
        });
      }else if (evt === 3){
        let customer = this.view.customer;
        let order_id = this.view.id;
        let type = this.view_type;

        this.$modal.show(SendEmail, {customer, type, order_id}, 
          { draggable: true, transition: 'pop-out',height: 'auto', clickToClose: false, adaptive: true, width: '500px' })
      }
    } 
  }
};
</script>

<style >
    hr.invoice-line{
        border-top: 1px solid black;
        width: 100%;
    }
    .billing-adr, .shipping-adr{
         font-size: 15px; 
         background-color:#366092; 
         color: #ffffff;
         padding:3px;
        border:1px solid #366092;
    }
    .invoice-header{
        background-color:#366092; 
        color: #ffffff;
    }
    #website-table th, #website-table td {
      /* border: 1px solid #366092; padding: 5px; */
    }
    .tab-row-hover {
        border: 1px solid #366092;
    }
    .add-info{
      margin-bottom: 20px;
    }
    .default-logo img{
      width: 125px;margin-top:30px;margin-left: 10px;
    }
     .return_product .el-input__inner{
      /* padding-left: 0px;
      padding-right: 8px; */
       border: 2px solid #3699ff;
       text-align: center !important;
      
    }
    .print-verified{
      display: none;
    }
    @media print {
       
        .print-section, .download-btn, .subheader, .action_btn, .action_dropdown {
            visibility: hidden;
            -webkit-print-color-adjust: exact;
        }
         .billing-adr, .shipping-adr{
         font-size: 15px; 
         background-color:#366092; 
         color: #ffffff;
         -webkit-print-color-adjust: exact;
        
    }

    .table th { 
        background-color: #366092 !important; 
         color: #ffffff !important;
         -webkit-print-color-adjust: exact;
    } 

    .printPageButton {
      display: none;
    }
    .table {
      width: 100%;
    }
    
    .print-verified{
      display: block;
      margin-top: 50px;
    }
    table {
    display: table; /* check so these really applies */
    width: 100%;
}
}
 
</style>